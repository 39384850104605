
var validateSocialCode = (rule, value, callback) => { //社会统一代码自定义
  const pwpattent = /^([0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}|[1-9]\d{14})$/
  if (pwpattent.test(value)) {
    callback();
  } else {
    if(value) {
      callback(
        new Error("请输入正确的组织机构代码/统一社会信用代码")
      );
    }else {
      callback()
    }

  }
};

var checkPhone = (rule, value, callback) => { //校验座机手机号
  let regPone = null
  let mobile = /^((13|14|15|17|18)[0-9]{1}\d{8})$/ // 最新16手机正则
  let tel = /^((0\d{2,3}-\d{7,8})|(1[3584]\d{9}))$/ // 座机
  if (value !== null && value.charAt(0) === '0') { // charAt查找第一个字符方法，用来判断输入的是座机还是手机号
    regPone = tel
  } else if (value !== null && value.charAt(0) !== '0') {
    regPone = mobile
  }
 if ( value  && !regPone.test(value)) {
    return callback(
      new Error("请输入正确的电话格式,其中座机格式'区号-座机号码'")
    )
  } else {
    callback()
  }
}

var checkProviceItem = (rule, value, callback) => {
  callback()
}

var password = null

function getPassword(value) {
  password = value
}

var validatePass = (rule, value, callback) => {
  const pwpattent = /^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\W_]).{8,16}$/;
  if (pwpattent.test(value)) {
    callback();
    getPassword(value)
  } else {
    callback(
      new Error("密码必须是8-16位大小写英文字母、数字、字符组合。")
    );
  }
};
var validatePass2 = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('请再次输入密码'));
  } else if (value !== password) {
    callback(new Error('两次输入密码不一致!'));
  } else {
    callback();
  }
};



export const RULES= {
  name: [
    {required: true, message: '请输入单位名称', trigger: 'blur'}
  ],
  fullName: [
    {required: true, message: '请输入单位全称', trigger: 'blur'}
  ],
  socialCode: [
    {required: false, validator:validateSocialCode, trigger: 'blur'}
  ],
  type: [
    {required: true, message: '请选择单位类别', trigger: 'change'}
  ],
  characteristic: [
    {required: true, message: '请选择单位性质', trigger: 'change'}
  ],
  systemId: [
    {required: true, message: '请选择单位所属系统', trigger: 'change'}
  ],
  contactAddress: [
    {required: true, message: '请输入单位通信地址', trigger: 'blur'}
  ],
  contactPerson: [
    {required: true, message: '请输入单位联系人', trigger: 'blur'}
  ],
  contactPhone: [
    {required: true, message: '请输入单位联系人手机号', trigger: 'blur'},
    {
      pattern: /^[1]([3-9])[0-9]{9}$/,
      message: '请正确输入11位手机号码',
      trigger: 'blur'
    }
  ],
  unitPhone: [
    {required: false, validator: checkPhone, trigger: 'blur'}
  ],
  supervisedUnitTree: [
    {required: true, message: '请选择上级监管单位', trigger: 'change'}
  ],
  unitGrade: [
    {required: true, message: '请选择单位级别', trigger: 'change'}
  ],
  guojia: [
  {required: true, message: '请选择所在国家', trigger: 'change'}
],
  provinceId: [
    {required: true, message: '请选择所在省', trigger: 'change'}
  ],
  cityId: [
    {required: true, message: '请选择所在市', trigger: 'change'}
  ],
  areaId: [
    {required: true, message: '请选择所在区', trigger: 'change'}
  ],
  streetId: [
    {required: true, message: '请选择所在街道', trigger: 'change'}
  ],
  communityId: [
    {required: true, message: '请选择所在社区', trigger: 'change'}
  ],
  userName: [
    {required: true, message: '请输入账号', trigger: 'blur'}
  ],
  passWord: [
    {required: true, validator: validatePass, trigger: 'blur'}
  ],
  confirmPassword: [
    {required: true, validator: validatePass2, trigger: 'blur'}
  ],
  proviceItem: [
    {required: true, validator: checkProviceItem, trigger: 'blur'}
  ],

}
